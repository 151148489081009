import { useEffect, useState } from "react";
import { PromotionEnum } from "../../utility/enum/promotionEnum";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import { useFormik } from "formik";
import * as yup from 'yup';
import { PromotionEnumKey } from "../../utility/enum/promotionEnumKey";
import { getIBE_API } from "../../getAPIUrl";
import { updatePromoScName } from "../../utils";

const usePromotion = (setFieldValue: any, openClass: any, isMobile: boolean = false, promotionOptionData: any = [], widgetValues: any = {}, isPageLoaded: boolean, setEventType?: any, eventType?: string) => {
    const {promotion: promotionValues} = widgetValues;
    const getInitialValues = () => {
        if(promotionValues?.promotionCode1 && promotionValues?.promotionCode2)  {
            return {
                code_1: promotionValues?.promotionCode1,
                code_2: promotionValues?.promotionCode2
            }
        } else if(promotionValues?.promotionCode1) {
            return {code_1: promotionValues?.promotionCode1}
        }
        else {
            return {}
        }
    }
    const [open, setOpen] = useState(false);
    const [error, setError] = useState<any>({});
    const [selectedPromotion, setSelectedPromotion] = useState<any>(promotionValues?.promotionType || PromotionEnum.RegularRate);
    const [boxHidden, setBoxHidden] = useState(false);
    const [promotionOption, setPromotionOption] = useState<any>([]);
    const [loader, setLoader] = useState(false);
    const [validationSchema, setValidationSchema] = useState({code_1: yup.string().required('This is required Field')});

    const promotionFormIk : any = useFormik({
        initialValues: {
            codeValues: getInitialValues()
        },
        validationSchema: yup.object().shape({
            codeValues: yup.object().shape({...validationSchema})
        }),
        onSubmit: (values: any) => {

        },
        enableReinitialize: true,
        validateOnChange: true,
        validateOnMount: true
    })

    useEffect(() => {
        if(open) {
            setSelectedPromotion(promotionValues?.promotionType || PromotionEnumKey.RegularRate);
            promotionFormIk.setFieldValue('codeValues', getInitialValues(), true)
        }
    }, [open])

    const getSubCategory = (promotionKey: any, havePromotionCode: boolean) =>{
        if(!havePromotionCode) return [];
        return [{
            promoScId: Math.random(),
            pmScName: 'Offer Code'
        }]
    }

    useEffect(() => {
        const contractedPromotionOption = promotionOptionData.find((x: { key: string; }) => x?.key == PromotionEnumKey.contractedRate);
        const contractedPromotion = contractedPromotionOption?.subCategories.map((promotion: any) => {
            return {
                promoCatId: promotion?.promoScId,
                promoCatName: promotion?.pmScName,
                description: promotion?.description,
                key: promotion?.key,
                //subCategories: getSubCategory(promotion?.key, promotion?.havePromotionCode)
            }
        })
        const filterPromotionOption = promotionOptionData.filter((x: { key: string; }) => x?.key !== PromotionEnumKey.contractedRate);

        const ObjRegularRate = {
            promoCatId: PromotionEnum.RegularRate,
            promoCatName: "Regular Rate",
            description: 'Regular rates are the most affordable rate currently offered for booking a hotel room.',
            key: PromotionEnumKey.RegularRate
        }
        if(!filterPromotionOption.some((x: { key: PromotionEnumKey; }) => x?.key === PromotionEnumKey.RegularRate)) {
            filterPromotionOption.unshift(ObjRegularRate);
        }
        let allPromotionList = [];
        if(contractedPromotion?.length > 0) {
            allPromotionList = filterPromotionOption.concat(contractedPromotion);
        }
        else {
            allPromotionList = filterPromotionOption;
        }
        if((promotionOptionData?.length > 1 && (!allPromotionList.some((x: { key: any; }) => x?.key === promotionValues?.promotionType))) || (isPageLoaded && promotionValues?.promotionCode1)) {
            setSelectedPromotion(PromotionEnumKey.RegularRate);
            promotionFormIk?.setFieldValue('codeValues', {}, true);
            setFieldValue('promotion', {'promotionType': PromotionEnumKey.RegularRate, promotionCode1: '', promotionCode2: ''}, true)
        }
        setPromotionOption(updatePromoScName(allPromotionList));

    }, [promotionOptionData])

    const getPromotionTooltip = (selectedPromotion: any) => {
        switch (selectedPromotion) {
            case PromotionEnum.RegularRate :
                return "Regular rates are the most affordable rate currently offered for booking a hotel room.";
            case PromotionEnum.CorporateRate :
                return "This is a discounted hotel rate extended to business travelers, typically negotiated between the hotel and corporate entity.";
            case PromotionEnum.AgencyRate :
                return "This is a discounted hotel rate offered that can be accessed through specific travel agents only fostering partnership and collaboration.";
            case PromotionEnum.Promotion_SpecialRate :
                return "This is a discounted hotel rate offered for a limited time, often tied to specific events, seasons, or marketing campaigns.";
            case PromotionEnum.GovernmentMilitaryRate :
                return "This rate plan offers specific discounts for Government/Military personnel for booking a hotel room.";
            case PromotionEnum.SeniorCitizenRate :
                return "This is a discounted hotel rate extensively available to older adults, typically aged 60 or older.";
            case PromotionEnum.CAA_AAA_Rate :
                return "This is a special hotel rate offered to members of the AAA or CAA for exclusive savings.";
            default:
                return "";
        }
    }

    const getSelectedPromotionText = (selectedPromotion: any, promotionCode: any) => {
        const promotion = promotionOption.find((promotion: { key: any; }) => promotion.key == selectedPromotion);
        if(promotion?.key == PromotionEnumKey.CorporateRate) {
            return `CORP: ${promotionCode}`
        }
        else if (promotion?.key == PromotionEnumKey.AgencyRate) {
            return `AGEN: ${promotionCode}`
        }
        else if (promotion?.key == PromotionEnumKey.Promotion_SpecialRate) {
            return `PROM: ${promotionCode}`
        }
        return promotion?.promoCatName;
    }

    const handleChange = (event: any) => {
        const selectedPromotion = event.target.value;
        const promotion = promotionOption.find((promotion: { key: any; }) => promotion?.key == selectedPromotion);
        if(!promotion?.subCategories || promotion?.subCategories?.length == 0) {
            setFieldValue('promotion', {'promotionType': selectedPromotion, promotionCode1: '', promotionCode2: ''}, true)
            if(!isMobile){
                setEventType("");
            }
            setOpen(false);
        }

        setSelectedPromotion(selectedPromotion);
        setError({});
        promotionFormIk?.setFieldValue('codeValues', {}, true);
        promotionFormIk?.setFieldTouched('codeValues', {}, true);
        const validations: any = {};
        promotion?.subCategories?.forEach((key: any, index: number) => {
            validations[`code_${index + 1}`] = yup.string().required('This is required Field')
        })
        setValidationSchema(validations);
        promotionFormIk.validateForm();
    };

    const applyPromotion = async() => {
        setLoader(true);
        const [value1, value2] = Object.values(promotionFormIk.values.codeValues);
        const promoCatId = promotionOptionData?.find((x: { key: any; }) => x.key === selectedPromotion)?.promoCatId;
        const requestPayload = constructReqBody(_.cloneDeep(widgetValues), promoCatId, value1, value2);
        const IBE_lOCATION_API = getIBE_API();
        const response = await axios.post(`${IBE_lOCATION_API}/Promotions/ValidatePromotions`, requestPayload);
        if(response?.data?.body?.isPromotionValid) {
            setFieldValue('promotion', {'promotionType': selectedPromotion, promotionCode1: value1, promotionCode2: value2}, true)
            if(!isMobile){
                setEventType("");
            }
            setOpen(false);
            setError({})
            setLoader(false);
        }
        else {
            const res = response?.data?.body;
            let errorRes = {};
            if(value2) {
                errorRes = {
                    code_1: {
                        isValid: !res.isVendorCodeValid,
                        errorMessage: res?.vendorCodeMessage
                    },
                    code_2: {
                        isValid: !res.isPromotionCodeValid,
                        errorMessage: res?.promotionCodeMessage
                    }
                }
            }
            else {
                errorRes = {
                    code_1: {
                        isValid: !res.isPromotionCodeValid,
                        errorMessage: res?.promotionCodeMessage
                    }
                }
            }
            setError(errorRes);
            setLoader(false)
        }
    }

    const onCodeChange = (code: string, promotionFormIk: any = () => {}) => {
        const touchedField = {..._.cloneDeep(promotionFormIk?.touched?.codeValues)}
        // const updateValues = {
        //     ...touchedField,
        //     [code]: true
        // }
        // console.log(promotionFormIk)
        // setFieldTouched('codeValues', updateValues, true)
        setError(false)
        //promotionFormIk.handleBlur();
        promotionFormIk.validateForm();
    }

    const handleClear = (key: string) => {
        setError(false);
        const values = _.cloneDeep(promotionFormIk.values.codeValues)
        const updateValues = {
            ...values,
            [key]: ''
        }

        promotionFormIk?.setFieldValue('codeValues', updateValues, true);
    }

    const handleCloseFilter = () => {
        setOpen(false);
    }

    const handleFilterOpen = () => {
        setOpen(prevState => !prevState);
        dropdownPosition();
    };

    const dropdownPosition = () =>{
        const boxElement = document.getElementById('IBE_Rate_Pos');
        if (!boxElement) return;

          const boxPosition = boxElement.getBoundingClientRect();
          const windowHeight = window.innerHeight;
          if (boxPosition.bottom < 0 || boxPosition.top + boxPosition.bottom > windowHeight) {
            //(boxPosition.top + boxPosition.bottom)
              setBoxHidden(true);
          }
          else {
              setBoxHidden(false);
        }
    }

    useEffect(() => {
      const handleScroll = () => {
        const delay = 750; // Adjust this value as needed
        const timerId = setTimeout(() => {
            // Call your function here or set a flag to indicate typing is finished
            dropdownPosition();
        }, delay);

        return () => {
            clearTimeout(timerId);
        };
      };

      window.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleScroll);
      };
    }, []);

    useEffect(() => {
        if(isMobile) {
            setOpen(openClass)
        }
    }, [openClass])

    useEffect(() => {
        if(open && eventType=="" && !isMobile) {
            setOpen(false);
        }
    }, [eventType]);

    const constructReqBody: any = (widgetData: any, selectedPromotion: string, value1: any, value2: any) => {
        const destination = widgetData?.destination;
        let country, city, propertyId, pincode;
        let obj = {};
        switch (Number(widgetData?.destination?.type)) {
            case 0:
                country = destination?.id;
                break;
            case 1:
                city = destination?.id;
                break;
            case 2:
                propertyId = destination?.id;
                break;
            case 3:
                pincode = destination?.id;
                break;
            default:
                break;
        }
        // To do create interface for below obj
        if (widgetData?.destination != undefined) {
            obj = {
                "chainID": widgetData?.chainID,
                "brandID": widgetData?.brandID,
                "propertyId": propertyId ?? "",
                "country": country ?? "",
                "city": city ?? "",
                "pincode": pincode ?? "",
                "checkIn": parseDateformat(getDateFromString(widgetData?.checkInDate)),
                "checkOut": parseDateformat(getDateFromString(widgetData?.checkOutDate)),
                "guest": widgetData?.guest.map((guest: any) => {
                    return {
                        adult: guest?.adultCount,
                        child: guest?.childCount,
                        roomNo: guest?.roomCount
                    }
                }),
                "promotionCategoryId": selectedPromotion,
                "PromVendorCode": value2 ? value1 : '',
                "promotionCode": value2 ? value2 : value1
            }
        }
        return removeBlankKeys(obj);
    }

    const removeBlankKeys = (obj: any) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (!obj[key] && obj[key] !== false) {
                    delete obj[key];
                }
            }
        }
        return obj;
    }

    const parseDateformat= (currentDate: any)=>{
        try {
            var month : any = parseInt(currentDate.getMonth()+1) > 9 ?
            (parseInt(currentDate.getMonth()+1)).toString() : '0'+(currentDate.getMonth()+1)
            var day: any = parseInt(currentDate.getDate()) >9 ? parseInt(currentDate.getDate()).toString() : '0'+ parseInt(currentDate.getDate()).toString()
        } catch{
        }

        return `${currentDate.getFullYear()}-${month}-${day}`;
    }

    const getDateFromString = (dateUnformatted:any): any => {
        try{
            if(dateUnformatted.includes('-')){
                var date_spilt : any= dateUnformatted.split("-");
                var day = date_spilt[2]?.includes('T') ? date_spilt[2].split('T')[0]: date_spilt[2];
                var formattedDate = new Date(date_spilt[0], date_spilt[1] - 1, day);
                return formattedDate;
            }
            else{
                var parsedDate = moment(dateUnformatted, "ddd MMM D YYYY");
                var date = parsedDate.format("YYYY-MM-DD");
                date_spilt= date.split("-");
                var formattedDate = new Date(date_spilt[0], date_spilt[1] - 1, date_spilt[2]);
                return formattedDate;
            }
        } catch {
            return new Date(dateUnformatted);
        }
    }

    return {
        getPromotionTooltip,
        promotionOption,
        getSelectedPromotionText,
        handleCloseFilter,
        handleFilterOpen,
        handleClear,
        onCodeChange,
        applyPromotion,
        handleChange,
        error,
        open,
        selectedPromotion,
        boxHidden,
        constructReqBody,
        loader,
        promotionFormIk
    }
}

export default usePromotion;