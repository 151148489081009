import { Typography } from "@mui/material";
import './WidgetStyle.css';
import MobileAddRoom from "../controls/MobileAddRoom";
import MobileApplyFilter from "../controls/mobileApplyFilter";
import { Box } from "@mui/material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { Formik } from "formik";
import * as yup from 'yup';
import { getIBE_API } from '../getAPIUrl';
import MobileSearchControl from "../controls/mobileSearchControl";
import RangeDateControl from "../controls/RangeDateControl/RangeDateControl";
import Toast from "../controls/toast";
import PromotionMobile from "../controls/promotion/promotionMobile";
import { PromotionEnum } from "../utility/enum/promotionEnum";
import { PromotionEnumKey } from "../utility/enum/promotionEnumKey";
import _ from "lodash";
import { cityCodeMapping } from "../utility/enum/cityCodeEnums";
import { Features, IsFeatureEnabled } from "../featureFlag";

enum locationType {
  country,
  city,
  property,
  pincode
}
interface ILocation {
  location?: any;
  id?: string;
  type: locationType;
}

function ReservationWidgetMobile(props: any) {
  const brandID = props?.props?.symbol?.brandid;
  const chainID = props?.props?.symbol?.chainid;
  const cityCodeDemand = props?.props?.symbol?.citycodedemand;
  const currencyMappingData = props?.props?.symbol?.currencymappingdata ? JSON.parse(props?.props?.symbol?.currencymappingdata) : [];
  const isMultiCurrencyFFEnable = props?.props?.symbol?.ismulticurrencyffenable;
  const preferredCurrency = props?.props?.symbol?.preferredcurrency ? JSON.parse(props?.props?.symbol?.preferredcurrency) : {};
  const destination: { location: string, type: number, id: string } = props?.props?.symbol?.destination ? JSON.parse(props?.props?.symbol?.destination) : null;
  const guest = props?.props?.symbol?.guest ? JSON.parse(props?.props?.symbol?.guest).map((ele: any) => {
    return {  roomCount: ele.RoomNo, adultCount : ele.Adult, childCount: ele.Child }
  }) : [{"roomCount":1,"adultCount":2,"childCount":0}];
  const hotelClass = props?.props?.symbol?.hotelclass ? JSON.parse(props?.props?.symbol?.hotelclass) : null;
  const urlCheckInDate = props?.props?.symbol?.checkindate;
  const urlCheckOutDate = props?.props?.symbol?.checkoutdate;
  const showSearch = props?.props?.symbol?.search;
  const backgroundColor1 = props?.props?.symbol?.backgroundprimarycolor;
  const backgroundColor2 = props?.props?.symbol?.backgroundprimarycolor;
  var booknowbuttoncolor = props?.props?.symbol?.widgetsearchcolorbutton || props?.props?.symbol?.booknowbuttoncolor;
  booknowbuttoncolor = (booknowbuttoncolor ==undefined || booknowbuttoncolor ==null || booknowbuttoncolor =='null')? '#546477' :  `${booknowbuttoncolor}`
  var  booknowbuttonhovercolor = props?.props?.symbol?.widgetsearchcolorhoverstate || props?.props?.symbol?.booknowbuttoncolor;
  booknowbuttonhovercolor=( booknowbuttonhovercolor ==undefined|| booknowbuttonhovercolor==null || booknowbuttonhovercolor =='null')? '#546477' : booknowbuttonhovercolor;
  const widgetFontColor = props?.props?.symbol?.widgetfontcolor;
  var widgetSearchFontButtonColor = props?.props?.symbol?.widgetsearchfontcolorbutton;
  widgetSearchFontButtonColor = (widgetSearchFontButtonColor ==undefined || widgetSearchFontButtonColor ==null ||  widgetSearchFontButtonColor =='null') ? '#fff':  `${widgetSearchFontButtonColor}`;
  var widgetSearchFontHoverStateColor = props?.props?.symbol?.widgetsearchfontcolorhoverstate;
  widgetSearchFontHoverStateColor= (widgetSearchFontHoverStateColor ==undefined || widgetSearchFontHoverStateColor ==null || widgetSearchFontHoverStateColor =='null') ?  '#fff' : `${widgetSearchFontHoverStateColor}`;
  const promotion = props?.props?.symbol?.promotion ? JSON.parse(props?.props?.symbol?.promotion) : null;
  const [formValues, setFormValues] = useState<any>()
  let today: any = new Date();
  const checkInDate = today.getFullYear() + '-' + parseInt(today.getMonth() + 1) + "-" + today.getDate();
  // Get the day and month
  const currentDay = today.getDate();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  // Increment the current date by one day to get the next date
  let nextDate: any = new Date(currentYear, currentMonth, currentDay + 1);
  // // Adjust the month if needed (case: last day of the month)
  // if (nextDate.getMonth() !== currentMonth) {
  //   nextDate  = new Date(currentYear, currentMonth+1, currentDay + 1);
  // }
  const checkOutDate = nextDate.getFullYear() + '-' + parseInt(nextDate.getMonth() + 1) + "-" + parseInt(nextDate.getDate());
  const [selectedDate, handleDateChange] = useState<any>([urlCheckInDate ? urlCheckInDate : checkInDate, urlCheckOutDate ? urlCheckOutDate : checkOutDate]);
  const [bookingURL, setBookingURL] = useState<string>('');
  const [maxChildAge, setMaxChildAge] = useState(17);
  const [loading, setLoading] = useState(false);
  const [isPromotionLoading, setIsPromotionLoading] = useState<boolean>(false);
  const [isBarDataLoading, setIsBarDataLoading] = useState<boolean>(false);
  const [barData, setBarData] = useState<any>([]);
  const [barCurrency, setBarCurrency] = useState<string>("");
  const [demandData, setDemandData] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>([{date: null, fetch: true}]);
  const [openGuest, setOpenGuest] = useState(false);
  const [openClass, setOpenClass] = useState(false);
  const [isPageLoaded, setPageLoaded] = useState(false);
  const params = window.location.href.split('?');
  const [destinationOptionList , setDestinationOptionList] = useState<any>([]);
  const [promotionData, setPromotionData] = useState<any>([{
      promoCatId: PromotionEnum.RegularRate,
      promoCatName: "Regular Rate",
      description: 'Regular rates are the most affordable rate currently offered for booking a hotel room.',
      key: PromotionEnumKey.RegularRate
  }]);

  const [toastObj, setToastObj] = useState({
    toastState: false,
    toastMessage: 'Destination is required!'
  })

  const parseDate = (currentDate: any) => {
    return currentDate.getFullYear() + '-' + parseInt(currentDate.getMonth() + 1) + "-" + parseInt(currentDate.getDate());
  }

  let formSchema = {
    chainID: chainID,
    brandID: brandID,
    destination: destination ? destination : {  id: '', location: '', type: 0},
    // hC: {
    //   b: hotelClass?.breakfast ? hotelClass?.breakfast : false,//breakfast:false,
    //   cB: hotelClass?.continentalBreakfast ? hotelClass?.continentalBreakfast : false,//continentalBreakfast: false,
    //   bB: hotelClass?.breakfastBuffet ? hotelClass?.breakfastBuffet : false,//breakfastBuffet: false,
    //   fB: hotelClass?.fullBreakfast ? hotelClass?.fullBreakfast : false,//fullBreakfast: false,
    //   eB: hotelClass?.englishBreakfast ? hotelClass?.englishBreakfast : false,//englishBreakfast: false,
    //   bF1: hotelClass?.breakfastfor1 ? hotelClass?.breakfastfor1 : false,//breakfastfor1: false,
    //   bF2: hotelClass?.breakfastfor2 ? hotelClass?.breakfastfor2 : false,//breakfastfor2: false,
    //   hBd: hotelClass?.halfBoard ? hotelClass?.halfBoard : false,//halfBoard: false,
    //   fBd: hotelClass?.fullBoard ? hotelClass?.fullBoard : false,//fullBoard: false,
    //   l: hotelClass?.lunch ? hotelClass?.lunch : false,//lunch: false,
    //   d: hotelClass?.dinner ? hotelClass?.dinner : false,//dinner: false,
    //   aI: hotelClass?.allInclusive ? hotelClass?.allInclusive : false,//allInclusive: false,
    //   starRating: hotelClass?.starRating ? hotelClass?.starRating?.split(",") : [1, 5]
    // },
    checkInDate: selectedDate[0],
    checkOutDate: selectedDate[1],
    guest: [...guest],
    promotion: {
      promotionType: promotion?.promotionType || PromotionEnumKey.RegularRate,
      promotionCode1: promotion?.promotionCode1 || '',
      promotionCode2: promotion?.promotionCode2 || '',
    }
  }

  function getLastDateOfNextMonth(dateStr: string) {
    let date = new Date(dateStr);
    let year = date.getFullYear();
    let month = date.getMonth();
    let nextMonth = month + 2;
    if (nextMonth > 11) {
        year += 1;
        nextMonth -= 12;
    }
    let firstDayOfNextMonth: any = new Date(year, nextMonth, 1);
    let lastDayOfNextMonth: any = new Date(firstDayOfNextMonth - 1);
    let formattedDate = lastDayOfNextMonth.toISOString().split('T')[0];
    return formattedDate;
  }

  function increaseDateByTwoMonths(dateString: string) {
    var parts = dateString.split('-');
    var year = parseInt(parts[0]);
    var month = parseInt(parts[1]);
    var day = parseInt(parts[2]);
    month += 2;
    if (month > 12) {
      year += Math.floor((month - 1) / 12);
      month = (month - 1) % 12 + 1;
    }
    var isLeapYear = (year % 4 == 0 && year % 100 != 0) || (year % 400 == 0);
    var daysInMonth = [31, (isLeapYear ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (day > daysInMonth[month - 1]) {
      day = daysInMonth[month - 1];
    }
    var newDateString = year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
    return newDateString;
  }

  const fetchBarData = async () => {
    let _startDate = startDate[0];
    if(formValues?.destination?.type == 2 && formValues?.destination?.id && barData.length<365 && IsFeatureEnabled(Features.DEMAND_CALENDAR) && _startDate?.fetch && !isBarDataLoading){
    // TODO : fetch URL from config
    const IBE_lOCATION_API = getIBE_API();
    // console.log('process.env', process.env)
    setIsBarDataLoading(true);
    const today = _startDate?.date? new Date(`${_startDate?.date}T00:00:00Z`): new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
      fetch(`${IBE_lOCATION_API}/rate/getPropertyBAR`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            propertyId: formValues?.destination?.type == 2 ? formValues?.destination?.id : '',
            checkIn: formattedDate(formValues?.checkInDate),
            checkOut: formattedDate(formValues?.checkOutDate),
            startDate: `${year}-${month}-${day}`,
            endDate: selectedDate.length>0 && Date.parse(selectedDate[1]) >= today.setMonth(today.getMonth() + 2)? getLastDateOfNextMonth(selectedDate[1]):increaseDateByTwoMonths(`${year}-${month}-${day}`),
            channels: [`8ba99da3-0006-4938-8a92-8efb6dbecbd1`]
        })
      })
      .then((response: any) => response.json())
      .then((data) => {
        if (data?.status && data.body?.propertyDateBARs && data.body?.propertyDateBARs.length>0) {
          let _barData = [...barData, ...data.body?.propertyDateBARs];
          setBarData(_barData)
          if(data.body?.currency!=null){
            setBarCurrency(data.body?.currency);
          }
          //setStartDate({date: increaseDateByTwoMonths(`${year}-${month}-${day}`), fetch: false});
        }else{
          console.log({data});
          //setStartDate([{date: _startDate.date, fetch: false}]);
        }
        setIsBarDataLoading(false)
      })
    }
  }

  function getCityCode(cityName: string) {
    const lowerCaseCityName = cityName.toLowerCase();
    for (const city of cityCodeMapping) {
        const lowerCaseCity = city.name.toLowerCase();
        if (lowerCaseCity === lowerCaseCityName) {
            return city.code;
        }
    }
    return null;
  }

  const decryptData = (cipherText:string) => {
    try{
        const crypto = require('crypto');
        const algorithm = 'aes-256-ctr';
        const enc : any= process.env.NEXT_PUBLIC_ENCRYPT_TOKEN;
        const ENCRYPTION_KEY : any= Buffer.from(enc, 'base64');
        let textParts: any = cipherText?.split(':');
        let iv = Buffer.from(textParts?.shift(), 'hex');
        let encryptedText = Buffer.from(textParts.join(':'), 'hex');
        let decipher = crypto.createDecipheriv(algorithm, Buffer.from(ENCRYPTION_KEY, 'hex'), iv);
        let decrypted = decipher.update(encryptedText);
        decrypted = Buffer.concat([decrypted, decipher.final()]);
        return decrypted.toString();
    }
    catch(er :any){
        console.log(er);
        return cipherText;
    }

}

  const fetchDemandData = async () => {
    if((formValues?.destination?.type == 1 || formValues?.destination?.type == 2) && formValues?.destination?.location && IsFeatureEnabled(Features.DEMAND_CALENDAR)){
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      const city = formValues?.destination?.type==1? formValues?.destination?.location: destinationOptionList?.filter((ele: any)=> ele?.name==formValues?.destination?.location)[0]?.city;
      const cityCode = getCityCode(city? city: cityCodeDemand);
      const demandBody = {
        startDate: `${`${year}-${month}-${day}`}`,
        endDate: increaseDateByTwoMonths(increaseDateByTwoMonths(`${`${year}-${month}-${day}`}`)),
        cityCode: cityCode
      }
      if(cityCode!=null){
        const response = await fetch(`${process.env.NEXT_PUBLIC_DOMAIN}/api/13f5ee5a-c5c3-4230-9f57-c9f487896f60_EXT`, {
          method: 'POST',
          body: JSON.stringify({demandBody: demandBody, reqType:'POST'})
        });

        if (response.ok) {
          let res = await response.json();
          let result  = JSON.parse(res.data);
          let _demandData: any;
          if(result.statusCode == 200){
            _demandData = [...result?.Data?.city];
            setDemandData(_demandData);
          }
        } else {
          console.log({response});
        }

      }
    }
  }

  useEffect(() => {
    setLoading(true);
    if((parent as any)?.window.document.getElementById("37316DCF-9BB6-4B80-BE26-7651D87C5F6B_outerRGdiv")){
      (parent as any).window.document.getElementById("37316DCF-9BB6-4B80-BE26-7651D87C5F6B_outerRGdiv").style.height = "auto";
    }
    const fetchBrandInfo = async () => {
      // TODO : fetch URL from config
      const IBE_lOCATION_API = getIBE_API();
      // console.log('process.env', process.env)

      fetch(`${IBE_lOCATION_API}/Search/getBrandInfo?brandID=${brandID}`)
        .then((response: any) => response.json())
        .then((data) => {
          if (data?.status) {
            // console.log({data});
            setBookingURL(data?.body?.bookingURL)
          }
        })
    }
    fetchBrandInfo();
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchPromotionData = async () => {
      // TODO : fetch URL from config
      const IBE_lOCATION_API = getIBE_API();
      // console.log('process.env', process.env)
      setIsPromotionLoading(true);
      fetch(`${IBE_lOCATION_API}/Promotions/getPromotionsCategories`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                brandID: brandID,
                chainID: chainID,
                country: formValues?.destination?.type === 0 ? formValues?.destination?.id : '',
                city: formValues?.destination?.type === 1 ? formValues?.destination?.id : '',
                propertyId: formValues?.destination?.type === 2 ? formValues?.destination?.id : '',
                checkInDate: formattedDate(formValues?.checkInDate),
                checkOutDate: formattedDate(formValues?.checkOutDate)
            })
        })
        .then((response: any) => response.json())
        .then((data) => {
          setIsPromotionLoading(false)
          if (data?.status) {
            setPromotionData(data?.body)
          }
          else {
            setPromotionData([]);
          }
        })
    }
    if(formValues?.destination?.id) {
      fetchPromotionData();
    }
  }, [JSON.stringify(formValues?.destination), formValues?.checkInDate, formValues?.checkOutDate]);

  useEffect(() => {
    setDemandData([]);
    setBarData([]);
    setBarCurrency("");
    setStartDate([{date: null, fetch: true}]);
    if(formValues?.destination?.type==2 || formValues?.destination?.type==1) {
      fetchDemandData();
      if(formValues?.destination?.type == 2) {
        fetchBarData();
      }
    }
  }, [JSON.stringify(formValues?.destination?.id), JSON.stringify(formValues?.destination?.type)]);

  useEffect(() => {
    if(!isBarDataLoading){
      if(startDate.length>1){
        let _startDate = [...startDate];
        console.log(_startDate.shift());
        setStartDate(_startDate);
      }else{
        setStartDate([{date: startDate[0]?.date, fetch: false}]);
      }
    }
  },[isBarDataLoading])

  useEffect(() => {
    fetchBarData();
  },[JSON.stringify(startDate)]);

  const bookNowHandler = (values: any) => {
    const query = createQueryString(values);
    let ibeBookingUrl = bookingURL + '/reservation/bID=' + brandID + '&cID=' + chainID + '&' + query.replace('hC=', '') + '';
    // console.log({ibeBookingUrl})
    window.open(ibeBookingUrl, '_blank')
  }

  const onClickbookNowHandler = (values: any, fn: any = {}, setFieldTouched: any) => {
    if (!values.destination?.id) {
      setToastObj({
        toastState: true,
        toastMessage: 'Destination is required!'
      })
    }
    else {
      let catId = promotionData?.find((x: { key: PromotionEnumKey; }) => x.key === values?.promotion?.promotionType)?.promoCatId;
      const subCatId = promotionData?.find((x: { key: PromotionEnumKey; }) => x.key === PromotionEnumKey.contractedRate)?.subCategories.find((y: { key: any; }) => y.key === values?.promotion?.promotionType)?.promoScId
      if(subCatId) {
        catId = promotionData?.find((x: { key: PromotionEnumKey; }) => x.key === PromotionEnumKey.contractedRate)?.promoCatId;
      }
      const requestCopy = _.cloneDeep(values);
      const request = {
        ...requestCopy,
        promotion: {
          ...requestCopy.promotion,
          catId: catId,
          subCatId: subCatId ? subCatId : '',
          promotionCode1: requestCopy.promotion?.promotionCode1 ? requestCopy.promotion?.promotionCode1 : '',
          promotionCode2: requestCopy.promotion?.promotionCode2 ? requestCopy.promotion?.promotionCode2 : '',
        }
      }
      const query = createQueryString(request);
      let ibeBookingUrl = bookingURL + '/reservation/bID=' + brandID + '&cID=' + chainID + '&' + query.replace('hC=', '') + '';
      if(params.length>1 && params[1]=="DEMAND_CALENDAR"){
        ibeBookingUrl += '&DEMAND_CALENDAR';
      }
      // console.log({ibeBookingUrl})
      if(showSearch){
        window.open(ibeBookingUrl, '_self')
      }else{
        window.open(ibeBookingUrl, '_blank')
      }
    }
  }

  const formattedDate =(date:any) => {
    const [year, month, day] = date?.split('-') ?? [];
    if(year == null || month == null || day == null) return date;
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }

  const createQueryString = (data: any) => {
    return Object.keys(data).filter(x => data[x] !== false).map(key => {
      let val = data[key]
      if (val === false) return ''
      if (val !== null && typeof val === 'object' && Object.keys(val).length > 0 && !Array.isArray(val)) val = createQueryString(val)
      if(key=='chainID' || key=='brandID'){
        return;
      }
      if(key=='destination' ){
        var newVal = val.split('&');
        val= `${newVal[0]}&${newVal[2]}`;
      }
      if(key=='checkInDate' || key == 'checkOutDate') {
        val = formattedDate(val);
      }
      if (Array.isArray(val) && (key === 'guest')) {
        const arrayParams = new Array();
        val.forEach(obj => {
          switch (obj?.roomCount) {
            case 1: {
              if (obj.adultCount > 0)
                arrayParams.push('ac1='+ obj.adultCount);
              if (obj.childCount > 0)
                arrayParams.push('cc1='+ obj.childCount);
              break;
            }
            case 2: {
              if (obj.adultCount > 0)
                arrayParams.push('ac2='+ obj.adultCount);
              if (obj.childCount > 0)
                arrayParams.push('cc2='+  obj.childCount);
              break;
            }
            case 3: {
              if (obj.adultCount > 0)
                arrayParams.push('ac3='+  obj.adultCount);
              if (obj.childCount > 0)
                arrayParams.push('cc3='+  obj.childCount);
              break;
            }
            case 4: {
              if (obj.adultCount > 0)
                arrayParams.push('ac4='+  obj.adultCount);
              if (obj.childCount > 0)
                arrayParams.push('cc4='+  obj.childCount);
              break;
            }
            case 5: {
              if (obj.adultCount > 0)
                arrayParams.push('ac5='+  obj.adultCount);
              if (obj.childCount > 0)
                arrayParams.push('cc5='+  obj.childCount);
              break;
            }

            default:
              break;
          }
        });
        val = `${arrayParams}`;
        return `${key}=[${encodeURIComponent(`${val}`.replace(/\s/g, '_'))}]`
      }
      return `${key}=${encodeURIComponent(`${val}`.replace(/\s/g, '_'))}`
    }).join('&')
  }

  // const createQueryString = (data: any) => {
  //   console.log("🚀 ~ file: ReservationWidget.tsx:118 ~ createQueryString ~ data:", data)
  //   return Object.keys(data).filter(x => data[x] !== false).map(key => {
  //     let val = data[key]
  //     console.log("🚀 ~ file: ReservationWidget.tsx:121 ~ returnObject.keys ~ val:", val)
  //     if (val === false) return ''
  //     if (val !== null && typeof val === 'object' && Object.keys(val).length > 0 && !Array.isArray(val)) val = createQueryString(val)
  //     if(key=='checkInDate' || key == 'checkOutDate'){
  //       val = formattedDate(val);
  //     }
  //     if (Array.isArray(val) && (key === 'guest')) {
  //       const arrayParams = new URLSearchParams();
  //       val.forEach(obj => {
  //         switch (obj?.roomCount) {
  //           case 1: {
  //             if (obj.adultCount > 0)
  //               arrayParams.append('ac1', obj.adultCount);
  //             if (obj.childCount > 0)
  //               arrayParams.append('cc1', obj.childCount);
  //             break;
  //           }
  //           case 2: {
  //             if (obj.adultCount > 0)
  //               arrayParams.append('ac2', obj.adultCount);
  //             if (obj.childCount > 0)
  //               arrayParams.append('cc2', obj.childCount);
  //             break;
  //           }
  //           case 3: {
  //             if (obj.adultCount > 0)
  //               arrayParams.append('ac3', obj.adultCount);
  //             if (obj.childCount > 0)
  //               arrayParams.append('cc3', obj.childCount);
  //             break;
  //           }
  //           case 4: {
  //             if (obj.adultCount > 0)
  //               arrayParams.append('ac4', obj.adultCount);
  //             if (obj.childCount > 0)
  //               arrayParams.append('cc4', obj.childCount);
  //             break;
  //           }
  //           case 5: {
  //             if (obj.adultCount > 0)
  //               arrayParams.append('ac5', obj.adultCount);
  //             if (obj.childCount > 0)
  //               arrayParams.append('cc6', obj.childCount);
  //             break;
  //           }

  //           default:
  //             break;
  //         }
  //       });
  //       val = arrayParams;
  //     }

  //     return `${key}=${encodeURIComponent(`${val}`.replace(/\s/g, '_'))}`
  //   }).join('&')
  // }

  const DisplayingErrorMessagesSchema = yup.object().shape({
    // destination: yup.object({
    //   location: yup.string().required('Destination is required')
    // }).required('Destination is required').typeError('Destination is required'),
    checkInDate: yup
      .date().nullable()
      .typeError('Check In date is required')
      .required('Check In date is required'),
    checkOutDate: yup
      .date().nullable()
      .required('Check Out date is required')
      .typeError('Check Out date is required')
  });

  const getNextDate = (dateString: string) => {
    const currentDate = new Date(dateString);
    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + 1);
    return nextDate;
  }

  const updateValue = (value: any, type: any, setFieldValue: any, widgetValues: any, touched: any) => {
    touched[type] = true;
    if (isNaN(Date.parse(value)) == false) {
      var v = parseDate(new Date(value))
      setFieldValue(type, v, true);
    }
    else {
      setFieldValue(type, '', true);
    }
    if (type === 'dateRange') {
      var updatedCheckInValue;
      var updatedCheckOutValue;
      if (isNaN(Date.parse(value.startDate._d)) === false) {
        updatedCheckInValue = parseDate(new Date(value.startDate._d));
        setFieldValue('checkInDate', updatedCheckInValue, true);
      }
      if (value?.endDate?._d && isNaN(Date.parse(value.endDate?._d)) === false) {
        if (Date.parse(value.endDate._d) === Date.parse(value.startDate._d)) {
          const nextDate = parseDate(getNextDate(value.startDate._i));
          setFieldValue('checkOutDate', nextDate, true);
          updatedCheckOutValue = nextDate;
        }
        else {
          updatedCheckOutValue = parseDate(new Date(value.endDate._d));
          setFieldValue('checkOutDate', updatedCheckOutValue, true);
        }
      }
      else {
        const nextDate = parseDate(getNextDate(value.startDate._d));
        setFieldValue('checkOutDate', nextDate, true);
        updatedCheckOutValue = nextDate;
      }
      handleDateChange([updatedCheckInValue, updatedCheckOutValue]);
    }
  }

  const widgetControl = (initialValues: any, errors: any, handleSubmit: any, touched: any, values: { destination: { location: any; }; guest: any; }, setFieldValue: any, isValid: any, setFieldError: any, setFieldTouched: any) => {
      setFormValues(values);
      return (
        <form onSubmit={handleSubmit} style={{paddingLeft:'8px'}}>
        <Box component="div" className="destinationCheckInCheckOut">
          <Toast toastState={toastObj.toastState} setToastState={setToastObj} toastMessage={toastObj.toastMessage}></Toast>
          <Box component="div" className="mobileDestination" style={{ backgroundImage: `linear-gradient(${backgroundColor1}, ${backgroundColor2})`, color: widgetFontColor? widgetFontColor: "#fff"}}>
            <Box component="div" sx={{ display: 'inline-block', width: "100%" }}>
              <Typography variant="h6" style={{color: widgetFontColor? widgetFontColor: "#fff"}}>DESTINATION</Typography>
              <Box component="div" sx={{ display: 'flex', justifyContent: "space-between", color: widgetFontColor? widgetFontColor: "#fff" }}>
                <MobileSearchControl setMaxChildAge={setMaxChildAge} brandID={brandID} chainID={chainID} touched={touched} errors={errors} setFieldValue={setFieldValue} initialValues={initialValues} dest={values?.destination} fontColor={widgetFontColor} booknowbuttoncolor={booknowbuttoncolor} booknowbuttonfontcolor={widgetSearchFontButtonColor} buttonHoverFontColor = {widgetSearchFontHoverStateColor} values={values} booknowbuttonhovercolor={booknowbuttonhovercolor} setPageLoaded={setPageLoaded} setDestinationOptionList={setDestinationOptionList}/>
                <LocationOnIcon sx={{ display: 'inline-block', float: 'right', margin: '5px 15px 0px 0px', fontSize: '24px', opacity: '100%', color: widgetFontColor && widgetFontColor!='null'? `${widgetFontColor} !important`: "#fff !important" }} />
              </Box>
            </Box>
          </Box>
          <Box component="div" className="checkIncheckOut" sx={{ backgroundImage: `linear-gradient(${backgroundColor1}, ${backgroundColor2})`, backgroundColor: "#526781", mt: "30px", borderRadius: '6pt', color: widgetFontColor? widgetFontColor: "#fff" }}>
            <RangeDateControl selectedDate={selectedDate} handleDateChange={(value: any) => updateValue(value, 'dateRange', setFieldValue, values, touched)} isMobile={true} fontColor={widgetFontColor} booknowbuttoncolor={booknowbuttoncolor} booknowbuttonfontcolor={widgetSearchFontButtonColor} buttonHoverFontColor = {widgetSearchFontHoverStateColor} booknowbuttonhovercolor={booknowbuttonhovercolor} barData={barData} navStartDate={startDate} setStartDate={setStartDate} demandData={demandData} barCurrency={barCurrency} isDemandCalendar={IsFeatureEnabled(Features.DEMAND_CALENDAR)?true:false} destinationType={values?.destination} propertyID={formValues?.destination?.type == 2 ? formValues?.destination?.id : destination?.id? destination?.id: ''} currencyMappingData={currencyMappingData} isMultiCurrencyFFEnable={isMultiCurrencyFFEnable} preferredCurrency={preferredCurrency}/>
          </Box>
          <Box component="div" className="mobileTraveller" sx={{ backgroundImage: `linear-gradient(${backgroundColor1}, ${backgroundColor2})`, color: widgetFontColor? widgetFontColor: "#fff" }}>
            <Box component="div">
              <Typography variant="h6" style={{color: widgetFontColor? widgetFontColor: "#fff"}}>GUESTS</Typography>
              <MobileAddRoom widgetValues={values} openGuest={openGuest} setOpenGuest={setOpenGuest} setFieldValue={setFieldValue} maxChildAge={maxChildAge} initialValues={initialValues} guestObj={values?.guest} fontColor={widgetFontColor} booknowbuttoncolor={booknowbuttoncolor} booknowbuttonhovercolor={booknowbuttonhovercolor}/>
            </Box>
          </Box>
          <Box component="div" className="mobileHotelClass" sx={{ backgroundImage: `linear-gradient(${backgroundColor1}, ${backgroundColor2})`, color: widgetFontColor? widgetFontColor: "#fff" }}
              onClick={()=>{
                if(values.destination?.location && !isPromotionLoading){
                  setOpenClass(true)
                  if((parent as any)?.changeIframeHeight){
                    if(parent.window.document.getElementById("86A3B1AA-E95E-45EE-B4E7-34B40AFAC538_Iframe")!=null){
                      (parent as any)?.changeIframeHeight(750);
                    }
                  }
                }
              }}
          >
            <Box component="div">
              <Typography variant="h6" style={{color: widgetFontColor? widgetFontColor: "#fff"}}>SELECTED RATE</Typography>
              <PromotionMobile
                setFieldValue={setFieldValue}
                openClass ={openClass}
                setOpenClass={setOpenClass}
                widgetValues={values}
                isDisable={values.destination?.location}
                values={values}
                fontColor={widgetFontColor}
                booknowbuttoncolor={booknowbuttoncolor}
                booknowbuttonhovercolor={booknowbuttonhovercolor}
                buttonFontColor={widgetSearchFontButtonColor}
                buttonHoverFontColor={widgetSearchFontHoverStateColor}
                promotionData={promotionData}
                isPageLoaded={isPageLoaded}
              />
            </Box>
          </Box>
          <Box component="div" className="bookNow">
            <Button
              disabled={bookingURL === '' || bookingURL == null }
              sx={{
                backgroundColor: (booknowbuttoncolor ==undefined || booknowbuttoncolor ==null || booknowbuttoncolor =='null')? '#546477 !important' :  `${booknowbuttoncolor} !important`,
                color:  (widgetSearchFontButtonColor ==undefined || widgetSearchFontButtonColor ==null ||  widgetSearchFontButtonColor =='null') ? '#fff !important':  `${widgetSearchFontButtonColor} !important`,
                  width: '192px',
                  height: '70px',
              }}
              style={{marginBottom:"20px"}}
              type="button"
              onClick={() => onClickbookNowHandler(values, setFieldError, setFieldTouched)}
            >
              {showSearch ? "SEARCH" : "BOOK NOW"}
            </Button>
          </Box>
        </Box>
      </form>
      )
  }

  return (
    <Box>
      <Formik initialValues={formSchema} validationSchema={DisplayingErrorMessagesSchema} onSubmit={bookNowHandler}>
        {({ initialValues, errors, handleSubmit, touched, values, setFieldValue, isValid, setFieldError, setFieldTouched }) => (
            <>{widgetControl(initialValues, errors, handleSubmit, touched, values, setFieldValue, isValid, setFieldError, setFieldTouched)}</>
        )}
      </Formik>
    </Box>
  )
}


export default ReservationWidgetMobile;