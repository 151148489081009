import './WidgetStyle.css';
import {useState, useEffect} from 'react';
import { Box } from "@mui/material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Button from '@mui/material/Button';
import AddRoom from "../controls/AddRoom";
import VerticalDivider from '../controls/VerticalDivider';
import ApplyFilterComponent from '../controls/ApplyFilter';
import { Formik } from "formik";
import * as yup from 'yup';
import SearchControl from '../controls/SearchControl';
import { getIBE_API } from '../getAPIUrl';
import RangeDateControl from '../controls/RangeDateControl/RangeDateControl';
import Toast from '../controls/toast';
import { locationTypeEnum } from '../utility/enum/locationTypeEnum';
import Promotion from '../controls/promotion/promotion';
import { PromotionEnum } from '../utility/enum/promotionEnum';
import { PromotionEnumKey } from '../utility/enum/promotionEnumKey';
import _ from 'lodash';
import { cityCodeMapping } from '../utility/enum/cityCodeEnums';
import { Close } from '@mui/icons-material';
import { Features, IsFeatureEnabled } from '../featureFlag';
import { formatDateToIOS } from '../utility/function/date';

interface ILocation {
  location?: any;
  id?: string;
  type: locationTypeEnum;
}

function ReservationWidget(props: any) {
  const brandID = props?.props?.symbol?.brandid
  const chainID = props?.props?.symbol?.chainid
  const backgroundColor1 = props?.props?.symbol?.backgroundprimarycolor;
  const backgroundColor2 = props?.props?.symbol?.backgroundprimarycolor;
  var booknowbuttoncolor = props?.props?.symbol?.widgetsearchcolorbutton || props?.props?.symbol?.booknowbuttoncolor;
  booknowbuttoncolor = (booknowbuttoncolor ==undefined || booknowbuttoncolor ==null || booknowbuttoncolor =='null')? '#546477' :  `${booknowbuttoncolor}`
  var  booknowbuttonhovercolor = props?.props?.symbol?.widgetsearchcolorhoverstate || props?.props?.symbol?.booknowbuttoncolor;
  booknowbuttonhovercolor=( booknowbuttonhovercolor ==undefined|| booknowbuttonhovercolor==null || booknowbuttonhovercolor =='null')? '#546477' : booknowbuttonhovercolor;
  const widgetFontColor = props?.props?.symbol?.widgetfontcolor;
  var widgetSearchFontButtonColor = props?.props?.symbol?.widgetsearchfontcolorbutton;
  widgetSearchFontButtonColor = (widgetSearchFontButtonColor ==undefined || widgetSearchFontButtonColor ==null ||  widgetSearchFontButtonColor =='null') ? '#fff':  `${widgetSearchFontButtonColor}`;
  var widgetSearchFontHoverStateColor = props?.props?.symbol?.widgetsearchfontcolorhoverstate;
  widgetSearchFontHoverStateColor= (widgetSearchFontHoverStateColor ==undefined || widgetSearchFontHoverStateColor ==null || widgetSearchFontHoverStateColor =='null') ?  '#fff' : `${widgetSearchFontHoverStateColor}`;
  const [destinationList, setDestinationList] = useState<any>();
  const [formValues, setFormValues] = useState<any>()
  let today: any = new Date();
  const checkInDate = today.getFullYear() +'-'+ parseInt(today.getMonth() + 1) + "-" + today.getDate() ;
  // Get the day and month
  const currentDay = today.getDate();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();

  // Increment the current date by one day to get the next date
  let nextDate : any= new Date(currentYear, currentMonth, currentDay + 1);
  // // Adjust the month if needed (case: last day of the month)
  // if (nextDate.getMonth() !== currentMonth) {
  //   nextDate  = new Date(currentYear, currentMonth+1, currentDay + 1);
  // }
  const checkOutDate = nextDate.getFullYear()+'-'+parseInt(nextDate.getMonth() + 1) + "-" + parseInt(nextDate.getDate());
  const [selectedDate, handleDateChange] = useState<any>([checkInDate, checkOutDate]);
  const [bookingURL, setBookingURL] = useState<string>('');
  const [maxChildAge, setMaxChildAge] = useState(17);
  const [loading, setLoading] = useState(false);
  const [isPromotionLoading, setIsPromotionLoading] = useState<boolean>(false);
  const [isBarDataLoading, setIsBarDataLoading] = useState<boolean>(false);
  const [barData, setBarData] = useState<any>([]);
  const [barCurrency, setBarCurrency] = useState<string>("");
  const [demandData, setDemandData] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>([{date: null, fetch: true}]);
  const [isPageLoaded, setPageLoaded] = useState(false);
  const [eventType, setEventType] = useState("");
  const params = window.location.href.split('?');
  const [promotionData, setPromotionData] = useState<any>([{
      "promoCatId": PromotionEnum.RegularRate,
      "promoCatName": "Regular Rate"
  }]);

  const [toastObj, setToastObj] = useState({
    toastState: false,
    toastMessage: ''
  })

  let formSchema = {
    chainID: chainID,
    brandID: brandID,
    destination: {
      location: '',
      type: 0,
      id: ''
    },
    checkInDate: formatDateToIOS(selectedDate[0]),
    checkOutDate: formatDateToIOS(selectedDate[1]),
    guest: [{
      roomCount: 1,
      adultCount: 2,
      childCount: 0
    }],
    promotion: {
      promotionType: PromotionEnumKey.RegularRate,
      promotionCode1: '',
      promotionCode2: '',
    }
  }

  const parseDate = (currentDate: any) => {
    return currentDate.getFullYear() + '-'+ parseInt(currentDate.getMonth() + 1) + "-" + parseInt(currentDate.getDate());
  }

  function getLastDateOfNextMonth(dateStr: string) {
    let date = new Date(dateStr);    
    let year = date.getFullYear();
    let month = date.getMonth();
    let nextMonth = month + 2;
    if (nextMonth > 11) {
        year += 1;
        nextMonth -= 12;
    }
    let firstDayOfNextMonth: any = new Date(year, nextMonth, 1);
    let lastDayOfNextMonth: any = new Date(firstDayOfNextMonth - 1);
    let formattedDate = lastDayOfNextMonth.toISOString().split('T')[0];
    return formattedDate;
  }

  function increaseDateByTwoMonths(dateString: string) {
    var parts = dateString.split('-');
    var year = parseInt(parts[0]);
    var month = parseInt(parts[1]);
    var day = parseInt(parts[2]);
    month += 2;
    if (month > 12) {
      year += Math.floor((month - 1) / 12);
      month = (month - 1) % 12 + 1;
    }
    var isLeapYear = (year % 4 == 0 && year % 100 != 0) || (year % 400 == 0);
    var daysInMonth = [31, (isLeapYear ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (day > daysInMonth[month - 1]) {
      day = daysInMonth[month - 1];
    }
    var newDateString = year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
    return newDateString;
  }

  const fetchBarData = async () => {
    let _startDate = startDate[0];
    if(formValues?.destination?.type == 2 && formValues?.destination?.id && barData.length<365 && IsFeatureEnabled(Features.DEMAND_CALENDAR) && _startDate?.fetch && !isBarDataLoading){
      const IBE_lOCATION_API = getIBE_API();
      setIsBarDataLoading(true);
      const today = _startDate?.date? new Date(`${_startDate?.date}T00:00:00Z`): new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      fetch(`${IBE_lOCATION_API}/rate/getPropertyBAR`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            propertyId: formValues?.destination?.type == 2 ? formValues?.destination?.id : '',
            checkIn: formattedDate(formValues?.checkInDate),
            checkOut: formattedDate(formValues?.checkOutDate),
            startDate: `${year}-${month}-${day}`,
            endDate: selectedDate.length>0 && Date.parse(selectedDate[1]) >= today.setMonth(today.getMonth() + 2)? getLastDateOfNextMonth(selectedDate[1]):increaseDateByTwoMonths(`${year}-${month}-${day}`),
            channels: [`8ba99da3-0006-4938-8a92-8efb6dbecbd1`]
        })
      })
      .then((response: any) => response.json())
      .then((data) => {
        if (data?.status && data.body?.propertyDateBARs && data.body?.propertyDateBARs.length>0) {
          let _barData: any;
          if(_startDate.date==null){
            _barData = [...data.body?.propertyDateBARs];
          }else{
            _barData = [...barData, ...data.body?.propertyDateBARs];
          }
          setBarData(_barData)
          if(data.body?.currency!=null){
            setBarCurrency(data.body?.currency);
          }
        }else{
          console.log({data});
          //setStartDate({...startDate, fetch: false});
        }
        setIsBarDataLoading(false)
      })
    }
  }

  function getCityCode(cityName: string) {
    const lowerCaseCityName = cityName.toLowerCase();
    for (const city of cityCodeMapping) {
        const lowerCaseCity = city.name.toLowerCase();
        if (lowerCaseCity === lowerCaseCityName) {
            return city.code;
        }
    }
    return null;
  }

  const decryptData = (cipherText:string) => {
    try{
        const crypto = require('crypto');
        const algorithm = 'aes-256-ctr';
        const enc : any= process.env.NEXT_PUBLIC_ENCRYPT_TOKEN;
        const ENCRYPTION_KEY : any= Buffer.from(enc, 'base64');
        let textParts: any = cipherText?.split(':');
        let iv = Buffer.from(textParts?.shift(), 'hex');
        let encryptedText = Buffer.from(textParts.join(':'), 'hex');
        let decipher = crypto.createDecipheriv(algorithm, Buffer.from(ENCRYPTION_KEY, 'hex'), iv);
        let decrypted = decipher.update(encryptedText);
        decrypted = Buffer.concat([decrypted, decipher.final()]);
        return decrypted.toString();
    }
    catch(er :any){
        console.log(er);
        return cipherText;
    }

}

  const fetchDemandData = async () => {
    if((formValues?.destination?.type == 1 || formValues?.destination?.type == 2) && formValues?.destination?.location && demandData.length<120 && IsFeatureEnabled(Features.DEMAND_CALENDAR)){
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      const city = formValues?.destination?.type==1? formValues?.destination?.location: destinationList.filter((ele: any)=> ele?.name==formValues?.destination?.location)[0]?.city;
      const cityCode = getCityCode(city);
      const demandBody = {
        startDate: `${`${year}-${month}-${day}`}`,
        endDate: increaseDateByTwoMonths(increaseDateByTwoMonths(`${`${year}-${month}-${day}`}`)),
        cityCode: cityCode
      }
      if(cityCode!=null){
        const response = await fetch(`${process.env.NEXT_PUBLIC_DOMAIN}/api/13f5ee5a-c5c3-4230-9f57-c9f487896f60_EXT`, {
          method: 'POST',
          body: JSON.stringify({demandBody: demandBody, reqType:'POST'})
        });

        if (response.ok) {
          let res = await response.json();
          let result  = JSON.parse(res.data);
          let _demandData: any;
          if(result.statusCode == 200){
            _demandData = [...result?.Data?.city];  
            setDemandData(_demandData);
          }
        }
      }
    }
  }

  useEffect(() => {
    if((parent as any)?.changeIframeHeight){
      const handleClickOutside = (event: any) => {
        if(parent.window.document.getElementById("86A3B1AA-E95E-45EE-B4E7-34B40AFAC538_Iframe")!=null){
          if (event.target.closest("#86A3B1AA-E95E-45EE-B4E7-34B40AFAC538_Iframe")==null) {
              console.log('Click event detected outside the iframe:', event);
              setEventType("");
          }
        }
      };
      if((parent as any).window.document.getElementById("86A3B1AA-E95E-45EE-B4E7-34B40AFAC538_Iframe")){
        (parent as any).window.document.getElementById("86A3B1AA-E95E-45EE-B4E7-34B40AFAC538_Iframe").style.position = "absolute";
        (parent as any).window.document.getElementById("86A3B1AA-E95E-45EE-B4E7-34B40AFAC538_Iframe").style.zIndex = "99";
      }
      (parent as any).changeIframeHeight(100);

      (parent as any).addEventListener('click', handleClickOutside);

      return () => {
          (parent as any).removeEventListener('click', handleClickOutside);
      };
    }
  }, []);

  useEffect(() => {
    if((parent as any)?.changeIframeHeight){
      if(parent.window.document.getElementById("86A3B1AA-E95E-45EE-B4E7-34B40AFAC538_Iframe")!=null){
        if(eventType === "onMouseClick" || eventType === "addRoomClick" || eventType === "promoClick"){
          (parent as any)?.changeIframeHeight(600);
        }else if(eventType === ""){
          (parent as any).changeIframeHeight(110);
        }
      }
    }
  },[eventType]);

  useEffect(() => {
    setLoading(true);
    const fetchBrandInfo = async () => {
      // TODO : fetch URL from config
      const IBE_lOCATION_API =  getIBE_API();

      fetch(`${IBE_lOCATION_API}/Search/getBrandInfo?brandID=${brandID}`)
        .then((response: any) => response.json())
        .then((data) => {
          if (data?.status) {
            const origin = typeof window !== undefined ? window?.origin : '';
            if(origin?.includes('localhost')) {
              setBookingURL('http://localhost:3000')
            }
            else {
              setBookingURL(data?.body?.bookingURL)
            }
          }
        })
    }
    fetchBrandInfo();
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchPromotionData = async () => {
      // TODO : fetch URL from config
      const IBE_lOCATION_API = getIBE_API();
      // console.log('process.env', process.env)
      setIsPromotionLoading(true);
      fetch(`${IBE_lOCATION_API}/Promotions/getPromotionsCategories`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                brandID: brandID,
                chainID: chainID,
                country: formValues?.destination?.type === 0 ? formValues?.destination?.id : '',
                city: formValues?.destination?.type === 1 ? formValues?.destination?.id : '',
                propertyId: formValues?.destination?.type === 2 ? formValues?.destination?.id : '',
                checkInDate: formattedDate(formValues?.checkInDate),
                checkOutDate: formattedDate(formValues?.checkOutDate)
            })
        })
        .then((response: any) => response.json())
        .then((data) => {
          setIsPromotionLoading(false)
          if (data?.status) {
            setPromotionData(data?.body)
          }
          else {
            setPromotionData([]);
          }
        })
    }
    fetchPromotionData();
  }, [JSON.stringify(formValues?.destination?.id), formValues?.checkInDate, formValues?.checkOutDate]);

  useEffect(() => {
    setDemandData([]);
    setBarData([]);
    setBarCurrency("");
    setStartDate([{date: null, fetch: true}]);
    if(formValues?.destination?.type==2 || formValues?.destination?.type==1) {
      fetchDemandData();
      if(formValues?.destination?.type == 2) {
        fetchBarData();
      }
    }
  }, [JSON.stringify(formValues?.destination?.id), JSON.stringify(formValues?.destination?.type)]);

  useEffect(() => {
    if(!isBarDataLoading){
      if(startDate.length>1){
        let _startDate = [...startDate];
        console.log(_startDate.shift());
        setStartDate(_startDate);
      }else{
        setStartDate([{date: startDate[0]?.date, fetch: false}]);
      }
    }
  },[isBarDataLoading])

  useEffect(() => {
      fetchBarData();
  },[JSON.stringify(startDate)]);

  const getDestinationKey = (key: any) => {
    key = parseInt(key);
    switch(key) {
      case locationTypeEnum.country:
        return 'countryId';
      case locationTypeEnum.city:
        return 'cityId';
      case locationTypeEnum.property:
        return 'propertyId'
      default :
        return 'pincode'
    }
  }

  const bookNowHandler = (values: any) => {
    if(!values.destination?.id) {
      setToastObj({
        toastState: true,
        toastMessage: 'Destination is required!'
      })
    }
    else {
      let catId = promotionData?.find((x: { key: PromotionEnumKey; }) => x.key === values?.promotion?.promotionType)?.promoCatId;
      const subCatId = promotionData?.find((x: { key: PromotionEnumKey; }) => x.key === PromotionEnumKey.contractedRate)?.subCategories.find((y: { key: any; }) => y.key === values?.promotion?.promotionType)?.promoScId
      if(subCatId) {
        catId = promotionData?.find((x: { key: PromotionEnumKey; }) => x.key === PromotionEnumKey.contractedRate)?.promoCatId;
      }
      const requestCopy = _.cloneDeep(values);
      const request = {
        ...requestCopy,
        promotion: {
          ...requestCopy.promotion,
          catId: catId,
          subCatId: subCatId ? subCatId : '',
          promotionCode1: requestCopy.promotion?.promotionCode1 ? requestCopy.promotion?.promotionCode1 : '',
          promotionCode2: requestCopy.promotion?.promotionCode2 ? requestCopy.promotion?.promotionCode2 : '',
        }
      }
      let key = getDestinationKey(values.destination.type);
      const isSingleProperty = destinationList.filter((x: { [x: string]: string; }) => x[key] === values.destination.id).length <= 1;
      const query = createQueryString(request);
      let ibeBookingUrl =bookingURL+'/reservation/bID=' + brandID + '&cID=' + chainID + '&' + query.replace('hC=', '') + '&isSingleProperty='+isSingleProperty+'';
      if(params.length>1 && params[1]=="DEMAND_CALENDAR"){
        ibeBookingUrl += '&DEMAND_CALENDAR';
      }
      console.log('ibebookingURL',ibeBookingUrl.replace('&&&','&'))
      // ADDED FOR SAFARI BROWSER
      setTimeout(() => {
        window.open(ibeBookingUrl.replace('&&&','&'), '_blank')
      })

    }
  }

  const formattedDate =(date:any) => {
    const [year, month, day] = date?.split('-') ?? [];
    if(year == null || month == null || day == null) return date;
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  }

  const createQueryString = (data: any) => {
    return Object.keys(data).filter(x => data[x] !== false).map(key => {
      let val = data[key]
      if (val === false) return ''
      if (val !== null && typeof val === 'object' && Object.keys(val).length > 0 && !Array.isArray(val)) val = createQueryString(val)
      if(key=='chainID' || key=='brandID'){
        return;
      }
      if(key=='destination' ){
        var newVal = val.split('&');
        val= `${newVal[0]}&${newVal[2]}`;
      }
      if(key=='checkInDate' || key == 'checkOutDate') {
        val = formattedDate(val);
      }
      if (Array.isArray(val) && (key === 'guest')) {
        const arrayParams = new Array();
        val.forEach(obj => {
          switch (obj?.roomCount) {
            case 1: {
              if (obj.adultCount > 0)
                arrayParams.push('ac1='+ obj.adultCount);
              if (obj.childCount > 0)
                arrayParams.push('cc1='+ obj.childCount);
              break;
            }
            case 2: {
              if (obj.adultCount > 0)
                arrayParams.push('ac2='+ obj.adultCount);
              if (obj.childCount > 0)
                arrayParams.push('cc2='+  obj.childCount);
              break;
            }
            case 3: {
              if (obj.adultCount > 0)
                arrayParams.push('ac3='+  obj.adultCount);
              if (obj.childCount > 0)
                arrayParams.push('cc3='+  obj.childCount);
              break;
            }
            case 4: {
              if (obj.adultCount > 0)
                arrayParams.push('ac4='+  obj.adultCount);
              if (obj.childCount > 0)
                arrayParams.push('cc4='+  obj.childCount);
              break;
            }
            case 5: {
              if (obj.adultCount > 0)
                arrayParams.push('ac5='+  obj.adultCount);
              if (obj.childCount > 0)
                arrayParams.push('cc5='+  obj.childCount);
              break;
            }

            default:
              break;
          }
        });
        val = `${arrayParams}`;
        return `${key}=[${encodeURIComponent(`${val}`.replace(/\s/g, '_'))}]`
      }
      return `${key}=${encodeURIComponent(`${val}`.replace(/\s/g, '_'))}`
    }).join('&')
  }

  const DisplayingErrorMessagesSchema = yup.object().shape({
    destination: yup.object({
      location: yup.string().required('Destination is required')
    }).required('Destination is required').typeError('Destination is required'),
    checkInDate: yup
      .date().nullable()
      .typeError('Check In date is required')
      .required('Check In date is required'),
    checkOutDate: yup
      .date().nullable()
      .required('Check Out date is required')
      .typeError('Check Out date is required')
  });

  const getNextDate = (dateString: string) => {
    const currentDate = new Date(dateString);
    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + 1);
    return nextDate;
  }

  const updateValue = (value: any, type: any, setFieldValue: any, widgetValues: any, touched: any) => {
    touched[type] = true;
    if (isNaN(Date.parse(value)) == false) {
      var v = parseDate(new Date(value))
      setFieldValue(type, v, true);
    }
    else {
      setFieldValue(type, '', true);
    }
    if (type === 'dateRange') {
      var updatedCheckInValue;
      var updatedCheckOutValue;
      if (isNaN(Date.parse(value.startDate._d)) === false) {
        updatedCheckInValue = formatDateToIOS(parseDate(new Date(value.startDate._d)));
        setFieldValue('checkInDate', updatedCheckInValue, true);
      }
      if (value?.endDate?._d && isNaN(Date.parse(value.endDate?._d)) === false) {
        if (Date.parse(value.endDate._d) === Date.parse(value.startDate._d)) {
          const nextDate = formatDateToIOS(parseDate(getNextDate(value.startDate._i)));
          setFieldValue('checkOutDate', nextDate, true);
          updatedCheckOutValue = nextDate;
        }
        else {
          updatedCheckOutValue = formatDateToIOS(parseDate(new Date(value.endDate._d)));
          setFieldValue('checkOutDate', updatedCheckOutValue, true);
        }
      }
      else {
        const nextDate = formatDateToIOS(parseDate(getNextDate(value.startDate._d)));
        setFieldValue('checkOutDate', nextDate, true);
        updatedCheckOutValue = nextDate;
      }
      handleDateChange([updatedCheckInValue, updatedCheckOutValue]);
    }
  }

  const handleCloseIcon =(setFieldError: any) => {
    setFieldError('destination', '', null);
  }

  const widgetControl = (initialValues: any, errors: any, handleSubmit: any, touched: any, values: { destination: { location: any; }; }, setFieldValue: any, isValid: any, setFieldError: any, setFieldTouched: any) => {
    setFormValues(values);
    return (
      <form onSubmit={handleSubmit}>
        <Box component="div">
          <Box component="div" className='widgetData'>
            <Box component="div" className="locationCity" style={{backgroundImage:`linear-gradient(${backgroundColor1}, ${backgroundColor2})`}}>
              <Box component="div" className="destination" onClick={() => {if(eventType!="addRoomClick" && eventType!="promoClick")setEventType("onMouseClick")}}>
                <Box component="span" className="labelText" sx={{color: widgetFontColor!==undefined? `${widgetFontColor} !important` : "#fff"}}>DESTINATION</Box>
                <Box component="div" sx={{ display: 'flex' }}>
                  <LocationOnIcon sx={{ verticalAlign: 'middle', width: '18px', height: '20px', position: 'relative', left: '-2px', marginTop: '16px', color: widgetFontColor!==undefined? widgetFontColor: "#fff" }}></LocationOnIcon>
                  <SearchControl
                    setMaxChildAge={setMaxChildAge}
                    brandID={brandID}
                    chainID={chainID}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    fontColor={widgetFontColor!==undefined? widgetFontColor: "#fff"}
                    setDestinationList={setDestinationList}
                    values={values}
                    booknowbuttoncolor={booknowbuttoncolor}
                    booknowbuttonhovercolor={booknowbuttonhovercolor}
                    booknowbuttonfontcolor={widgetSearchFontButtonColor}
                    buttonHoverFontColor = {widgetSearchFontHoverStateColor}
                    setPageLoaded={setPageLoaded}
                    setEventType={setEventType}
                    eventType={eventType}
                  />
                </Box>
                {touched.destination && errors?.destination && <div className='destination-error-msg'><span>{JSON.stringify(errors?.destination?.location || errors?.destination).replace(/"/g, '')}</span><span> <Close onClick={() => handleCloseIcon(setFieldError)} sx={{fontSize: '15px', top: '3px', pl: '5px', position: 'relative', cursor: 'pointer', width: '25px !important'}} shapeRendering='true'/> </span></div>}
              </Box>
              <VerticalDivider />
              <Box component="div" onClick={() => {if(eventType!="addRoomClick" && eventType!="promoClick")setEventType("onMouseClick")}}>
                {/* <DateRangeControl touched={touched} errors={errors} selectedDate={selectedDate} handleDateChange={(value: any) => updateValue(value, 'dateRange', setFieldValue, values, touched)} /> */}
                <RangeDateControl
                  selectedDate={selectedDate}
                  handleDateChange={(value: any) => updateValue(value, 'dateRange', setFieldValue, values, touched)}
                  fontColor={widgetFontColor!==undefined? widgetFontColor: "#fff"}
                  booknowbuttoncolor={booknowbuttoncolor}
                  booknowbuttonhovercolor={booknowbuttonhovercolor}
                  booknowbuttonfontcolor={widgetSearchFontButtonColor}
                  buttonHoverFontColor = {widgetSearchFontHoverStateColor}
                  setEventType={setEventType}
                  eventType={eventType}
                  barData={barData}
                  navStartDate={startDate}
                  setStartDate={setStartDate}
                  demandData={demandData}
                  barCurrency={barCurrency}
                  isDemandCalendar={IsFeatureEnabled(Features.DEMAND_CALENDAR)?true:false}
                  destinationType={values?.destination}
                  propertyID={formValues?.destination?.type == 2 ? formValues?.destination?.id : ''}
                />
              </Box>
              <VerticalDivider />
              <Box component="div" className="controlLabel guestData" onClick={() => setEventType("addRoomClick")}>
                <Box component="span" className="labelText" sx={{color: widgetFontColor!==undefined? `${widgetFontColor} !important` : "#fff"}}>Guests</Box>
                <Box component="span" sx={{ position: 'relative', top: '51px', cursor: 'pointer' }}>
                    <AddRoom
                        widgetValues={values}
                        setFieldValue={setFieldValue}
                        booknowbuttoncolor={booknowbuttoncolor}
                        maxChildAge={maxChildAge}
                        fontColor={widgetFontColor!==undefined? widgetFontColor: "#fff"}
                        booknowbuttonhovercolor={booknowbuttonhovercolor}
                        setEventType={setEventType}
                        eventType={eventType}
                    />
                </Box>
              </Box>
              <VerticalDivider />
              <Box component="div" style={!(isValid && !!values.destination?.location) || isPromotionLoading ? { opacity: '.5' } : {}} className="controlLabel" onClick={() => setEventType("promoClick")}>
                <Box component="span" className="labelText" sx={{color: widgetFontColor!==undefined? `${widgetFontColor} !important`: "#fff"}} >Selected Rate</Box>
                <Box component="div"  >
                  <Promotion
                    setFieldValue={setFieldValue}
                    booknowbuttoncolor={booknowbuttoncolor}
                    booknowbuttonhovercolor={booknowbuttonhovercolor}
                    widgetValues={values}
                    buttonFontColor={widgetSearchFontButtonColor}
                    buttonHoverFontColor={widgetSearchFontHoverStateColor}
                    isDisable={isValid && !!values.destination?.location}
                    values={values}
                    fontColor={widgetFontColor!==undefined? widgetFontColor: "#fff"}
                    promotionData={promotionData}
                    isPromotionLoading={isPromotionLoading}
                    isPageLoaded={isPageLoaded}
                    brandID={brandID}
                    setEventType={setEventType}
                    eventType={eventType}
                  />
                </Box>
              </Box>
            </Box>

            <Box component="div" className="bookNowMain">
              <Button
                id='show-toast-button'
                disabled={bookingURL==='' || bookingURL==null }
                variant="contained" sx={{
                  backgroundColor: (booknowbuttoncolor ==undefined || booknowbuttoncolor ==null || booknowbuttoncolor =='null')? '#546477 !important' :  `${booknowbuttoncolor} !important`,
                  color:  (widgetSearchFontButtonColor ==undefined || widgetSearchFontButtonColor ==null ||  widgetSearchFontButtonColor =='null') ? '#fff !important':  `${widgetSearchFontButtonColor} !important`,
                  width: '192px',
                  height: '70px',
                  borderRadius: '6px',
                  fontSize: '14px',
                  boxShadow: '0px 3px 6px #00000029 !important',
                  '&:hover': {
                    'opacity': '70% !important',
                    backgroundColor:( booknowbuttonhovercolor ==undefined|| booknowbuttonhovercolor==null || booknowbuttonhovercolor =='null')? '#546477 !important' : `${booknowbuttonhovercolor} !important`,
                    color: (widgetSearchFontHoverStateColor ==undefined || widgetSearchFontHoverStateColor ==null || widgetSearchFontHoverStateColor =='null') ?  '#fff !important' : `${widgetSearchFontHoverStateColor} !important`,
                  }
              }} type="button" onClick={handleSubmit} className="bookNowResponsive">BOOK NOW</Button>
            </Box>
          </Box>
        </Box>
      </form>
    )
  }

  return (
    <>
      <Toast toastState={toastObj.toastState} setToastState={setToastObj} toastMessage={toastObj.toastMessage} />
      <Formik initialValues={formSchema} validationSchema={DisplayingErrorMessagesSchema} onSubmit={bookNowHandler} className="responsiveWidget">
        {({initialValues, errors, handleSubmit, touched, values, setFieldValue, isValid, setFieldError, setFieldTouched }: any): any => (
          <>{widgetControl(initialValues, errors, handleSubmit, touched, values, setFieldValue, isValid, setFieldError, setFieldTouched)}</>
        )}
      </Formik>
    </>
  )
}


export default ReservationWidget;