import _ from "lodash";
import { PromotionEnumKey } from "./utility/enum/promotionEnumKey";

const updatePromoScName = (promotionList: any) => {
    const data = _.cloneDeep(promotionList);
    // Loop through the body array
    if(data.length > 0) {
        data?.forEach((item: { subCategories: any[]; promoCatName: any; key: any; }) => {
            if(item) {
                item.promoCatName = updateCategoryName(item?.key) || item?.key;
                // Check if the item has subCategories
                if (item?.subCategories) {
                    // Loop through the subCategories array
                    item?.subCategories.forEach((subItem) => {
                        // Check if pmScName is "Travel Agency Rate"
                        if (subItem.key === "CORPCODE") {
                            // Update pmScName to "Agency Code"
                            subItem.pmScName = "Corporate Code";
                        }
                        else if(subItem.key === "AGENCYCODE") {
                            subItem.pmScName = "Agency Code";
                        }
                    });
                    item.subCategories = updateAndSortSubCategories(item?.subCategories);
                }
            }
        });
    }
    return data;
}

const updateCategoryName = (catKey: any) => {
    switch (catKey) {
        case PromotionEnumKey.RegularRate :
            return "Regular Rates";
        case PromotionEnumKey.CorporateRate :
            return "Corporate Rate";
        case PromotionEnumKey.AgencyRate :
            return "Agency Rate";
        case PromotionEnumKey.Promotion_SpecialRate :
            return "Promotional/Special Rate";
        case PromotionEnumKey.GovernmentMilitaryRate :
            return "Government/Military Rate";
        case PromotionEnumKey.SeniorCitizenRate :
            return "Senior Citizen Rate";
        case PromotionEnumKey.CAA_AAA_Rate :
            return "CAA/AAA Rate";
        default:
            return "";
    }
}

function updateAndSortSubCategories(item: any) {
    // Loop through the body array
    if (item) {
        // Sort the subCategories array alphabetically by "pmScName"
        item.sort((a: { pmScName: string; }, b: { pmScName: any; }) => {
            return a.pmScName.localeCompare(b.pmScName);
        });
    }

    return item;
}

export  {
    updatePromoScName
}